import PageHeader from "./PageHeader";
import Footer from "./Footer";
import Home from "./Home";
import { Route, Routes } from "react-router-dom";
import Privacy from "./Privacy";
import "./scss/main.scss";
import Imprint from "./Imprint";

function App() {
  return (
    <div className="page">
      <PageHeader />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/datenschutz" element={<Privacy />} />
        <Route path="/impressum" element={<Imprint />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
