import { Link } from "react-router-dom";

function Imprint() {
  return (
    <div className="section sec-imprint">
      <div className="container">
        <p className="title">
          Impressum
          <br />
          <p className="small">
            Gesellschaft für Versicherungsservice Moll mbH • Jupiterweg 1, 85586
            Poing • Tel: 08121 / 476784-0 • Fax: 08121 / 476784-9 • E-Mail:
            <Link to="mailto:info@gvsmoll.de">info@gvsmoll.de</Link>
          </p>
        </p>
        <p className="subtitle">Geschäftsführer</p>
        <p>Herr Klaus-Peter Moll, Frau Michaela Müller</p>
        <p className="subtitle">Handelsregisternummer</p>
        <p>HRB 23401, Amtsgericht München</p>
        <p className="subtitle">§34d GewO</p>
        <p className="subtitle">Berufsbezeichnung</p>
        <p>
          Versicherungsmakler mit Erlaubnis nach § 34d Abs. 1 Gewerbeordnung,
          Bundesrepublik Deutschland
        </p>
        <p className="subtitle">Registernummer</p>
        <p>D-8IRZ-B7T31-26</p>
        <p className="subtitle">
          Aufsichtsbehörde und zuständige Behörde für die Erlaubnis:
        </p>
        <p>
          Industrie- und Handelskammer für München und Oberbayern
          <br />
          Max-Joseph-Str. 2<br />
          80333 München Deutschland
          <br />
          <Link to="https://www.ihk-muenchen.de/" target="blank">
            https://www.ihk-muenchen.de/
          </Link>
        </p>
        <p className="subtitle">
          Die Eintragung kann wie folgt überprüft werden:
        </p>
        <p>
          DIHK | Deutsche Industrie- und Handelskammer
          <br />
          Breite Straße 29, 10178 Berlin
          <br />
          Telefon 0180-600-585-0 *<br />
          <Link to="www.vermittlerregister.info" target="blank">
            www.vermittlerregister.info
          </Link>
          <br />
          <span className="small">* 0,20 €/Anruf</span>
        </p>
        <p className="subtitle">Schlichtungsstellen</p>
        <p>
          Gemäß § 36 VSBG und § 17 Abs. 4 VersVermV teilen wir mit, dass wir
          verpflichtet und bereit sind an einem Streitbeilegungsverfahren
          teilzunehmen. Folgende Schlichtungsstellen können angerufen werden:
        </p>
        <p>
          Versicherungsombudsmann e.V.
          <br />
          Postfach 08 06 32, 10006 Berlin
          <br />
          <Link to="www.versicherungsombudsmann.de" target="blank">
            www.versicherungsombudsmann.de
          </Link>
        </p>
        <p>
          Ombudsmann Private Kranken- und Pflegeversicherung
          <br />
          Postfach 06 02 22, 10052 Berlin
          <br />
          <Link to="www.pkv-ombudsmann.de" target="blank">
            www.pkv-ombudsmann.de
          </Link>
        </p>
        <p>
          Europäische Kommission
          <br />
          Online-Streitbeilegungsplattform (OS-Plattform)
          <br />
          <Link to="http://ec.europa.eu/consumers/odr" target="blank">
            http://ec.europa.eu/consumers/odr
          </Link>
        </p>
        <p className="subtitle">
          Berufsrechtliche Regelungen sind insbesondere:
        </p>
        <ul className="list">
          <li>§ 34 d Gewerbeordnung</li>
          <li>§§ 59 - 68 VVG</li>
          <li>VersVermV</li>
        </ul>
        <p>
          Die berufsrechtlichen Regelungen können über die vom Bundesministerium
          der Justiz und von der juris GmbH betriebenen Homepage
          <Link to="www.gesetze-im-internet.de" target="blank">
            www.gesetze-im-internet.de
          </Link>{" "}
          eingesehen und abgerufen werden.
        </p>
        <p className="subtitle">Beschwerdemanagement</p>
        <p>
          Beschwerden sind in Textform an die Geschäftsleitung zu richten und
          werden im Rahmen unseres Beschwerdemanagements unverzüglich
          bearbeitet.
        </p>
        <p className="subtitle">Urheberrecht</p>
        <p>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
          jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
          sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
          wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
          Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
          eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
          werden wir derartige Inhalte umgehend entfernen.
        </p>
        <p className="subtitle">
          Information zur Einbeziehung von Nachhaltigkeitsrisiken bei der
          Beratungstätigkeit (Art. 3 TVO)
        </p>
        <p>
          Um Nachhaltigkeitsrisiken bei der Beratung einzubeziehen, werden im
          Rahmen der Auswahl von Anbietern (Finanzmarktteilnehmern) und deren
          Finanzprodukten deren zur Verfügung gestellte Informationen
          berücksichtigt. Anbieter, die erkennbar keine Strategie zur
          Einbeziehung von Nachhaltigkeitsrisiken in ihre
          Investitionsentscheidungen haben, werden ggf. nicht angeboten. Im
          Rahmen der Beratung wird ggf. gesondert dargestellt, wenn die
          Berücksichtigung der Nachhaltigkeitsrisiken bei der
          Investmententscheidung erkennbare Vor- bzw. Nachteile für den Kunden
          bedeuten. Über die Berücksichtigung von Nachhaltigkeitsrisiken bei
          Investitionsentscheidungen des jeweiligen Anbieters informiert dieser
          mit seinen vorvertraglichen Informationen. Fragen dazu kann der Kunde
          im Vorfeld eines möglichen Abschlusses ansprechen.
        </p>
        <p className="subtitle">
          Information zur Berücksichtigung nachteiliger Auswirkungen auf
          Nachhaltigkeitsfaktoren (Art. 4 TVO in Verbindung mit Art. 11 der
          Ergänzung zur TVO vom 01. Januar 2023)
        </p>
        <p className="subtitle">
          Erklärung über die Berücksichtigung der wichtigsten nachteiligen
          Auswirkungen auf Nachhaltigkeitsfaktoren bei der Anlage- und
          Versicherungsberatung
        </p>
        <p>
          Bei der Beratung ist es unser Ziel, Ihnen ein geeignetes
          Anlage-/Versicherungsanlageprodukt empfehlen zu können. Dabei
          berücksichtigen wir auch Ihre Nachhaltigkeitspräferenzen, sofern Sie
          dies wünschen. Hierbei können Sie festlegen, ob bei Ihrer Anlage
          ökologische und/oder soziale Werte sowie Grundsätze guter
          Unternehmensführung und/oder die wichtigsten nachteiligen Auswirkungen
          von Investitionsentscheidungen auf Nachhaltigkeitsfaktoren
          berücksichtigt werden sollen. Der Gesetzgeber hat je nach Art des
          Anlageziels (Investition in Unternehmen, Staaten, Immobilien etc.) in
          folgenden Bereichen „Indikatoren“ für die wichtigsten nachteiligen
          Auswirkungen ihrer Investitionsentscheidungen auf
          Nachhaltigkeitsfaktoren bestimmt:
        </p>
        <ul className="list">
          <li>Umwelt-, Sozial- und Arbeitnehmerbelange</li>
          <li>Die Achtung der Menschenrechte</li>
          <li>Die Bekämpfung von Korruption und Bestechung.</li>
        </ul>
        <p>
          Die Produktanbieter sind gesetzlich verpflichtet, eine Erklärung zu
          veröffentlichen, welche Strategie sie in Bezug auf die
          Berücksichtigung der wichtigsten nachteiligen Auswirkungen und den
          Umgang damit verfolgen. Dies bezieht sich insbesondere auf
          Treibhausgasemissionen, Wasserverbrauch, Biodiversität, Abfall,
          Soziales und Arbeitnehmerbelange (einschließlich Menschenrechte und
          Korruption). Wenn Sie sich dazu entscheiden, dass die wichtigsten
          nachteiligen Auswirkungen Ihrer Investitionsentscheidungen auf
          Nachhaltigkeitsfaktoren bei der Produktauswahl berücksichtigt werden
          sollen, beachten wir im Rahmen des Auswahlprozesses die von den
          Produktanbietern bereitgestellten Informationen sowie die von den
          Produktanbietern dargelegten Strategien. Eigene Einstufungs- und
          Auswahlmethoden zu den Informationen der Produktanbieter wenden wir
          nicht an. Es erfolgt keine gesonderte Prüfung der Angaben der
          Produktanbieter in Hinblick auf ihre Plausibilität.
        </p>
        <p className="subtitle">
          Informationen zur Vergütungspolitik bei der Berücksichtigung von
          Nachhaltigkeitsrisiken (Art. 5 TVO)
        </p>
        <p>
          Die Vergütung für die Vermittlung von Finanzprodukten wird nicht von
          den jeweiligen Nachhaltigkeitsrisiken beeinflusst.
        </p>
        <br />
        <br />
        <p>
          Redaktionelle Verantwortung: Herr Klaus-Peter Moll, Frau Michaela
          Vogel
        </p>
        <p>
          Bildnachweise:
          <br />
          Foto von{" "}
          <a href="https://unsplash.com/de/@charlesdeluvio?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
            charlesdeluvio
          </a>{" "}
          auf{" "}
          <a href="https://unsplash.com/de/fotos/mann-mit-macbook-Lks7vei-eAg?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
            Unsplash
          </a>
        </p>
      </div>
    </div>
  );
}

export default Imprint;
