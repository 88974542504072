import { Link } from "react-router-dom";

import { PiFilePdfLight } from "react-icons/pi";
import erstinfo from "../Erstinformation.pdf";

import vema from "../vema-logo.svg";

function Information() {
  return (
    <div className="quick-links">
      <div className="vema">
        <img src={vema} alt="Vema" />
      </div>
      <p>
        <Link to={erstinfo} target="blank">
          Erstinformation
          <PiFilePdfLight />
        </Link>
      </p>
      <p>
        <Link to="datenschutz">Datenschutz</Link>
      </p>
      <p>
        <Link to="impressum">Impressum</Link>
      </p>
    </div>
  );
}

export default Information;
