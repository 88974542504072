import Hero from "./Hero";
import Service from "./Service";
import Contact from "./Contact";

function Home() {
  return (
    <div className="page">
      <Hero />
      <Service />
      <Contact />
    </div>
  );
}

export default Home;
