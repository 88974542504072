import { MdLocalPhone } from "react-icons/md";
import { MdOutlineMailOutline } from "react-icons/md";
import pmoll from "./img/moll1.jpg";
import mmueller from "./img/mueller1.jpg";

function Contact() {
  return (
    <section className="section sec-contact">
      <div id="contact"></div>
      <div className="container">
        <div className="contact-info">
          <img src={mmueller} alt="Michaela Müller" />
          <div className="contact-info-details">
            <p className="contact-info-details-role">Geschäftsführerin</p>
            <p className="contact-info-details-name">Michaela Müller</p>

            <a href="mailto:mueller@gvsmoll.de">
              <MdOutlineMailOutline /> mueller@gvsmoll.de
            </a>
            <a href="tel:+4981214767841">
              <MdLocalPhone /> +49 (0) 8121 476 784 1
            </a>
          </div>
        </div>

        <div className="contact-info">
          <img src={pmoll} alt="Peter Moll" />
          <div className="contact-info-details">
            <p className="contact-info-details-role">Geschäftsführer</p>
            <p className="contact-info-details-name">Peter Moll</p>

            <a href="mailto:moll@gvsmoll.de">
              <MdOutlineMailOutline /> moll@gvsmoll.de
            </a>
            <a href="tel:+4981214767841">
              <MdLocalPhone /> +49 (0) 8121 476 784 5
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
