import { Link } from "react-router-dom";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import { MdLocalPhone } from "react-icons/md";

function Hero() {
  return (
    <>
      <section className="section hero">
        <div id="top"></div>
        <div className="container">
          <div className="hero-content">
            <p className="title">
              Versicherungen sind
              <br />
              Vertrauenssache
            </p>
            <p>
              Nur eine kontinuierliche Zusammenarbeit garantiert Sicherheit und
              einen spürbaren Nutzen für den Kunden. Um einen maßgeschneiderten
              Versicherungsschutz zu bieten, streben wir eine langfristige, alle
              Versicherungssparten umfassende und partnerschaftliche
              Zusammenarbeit mit unseren Kunden an.
            </p>
            <Link to="mailto:info@gvsmoll.de" className="contact-btn info-btn">
              <div>
                <HiOutlineChatAlt2 />
                <span>kontaktieren Sie uns</span>
              </div>
            </Link>
            <Link to="tel:+4981214767840" className="contact-btn phone-btn">
              <div>
                <MdLocalPhone />
                <span>kontaktieren Sie uns</span>
              </div>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}

export default Hero;
