import { Link } from "react-router-dom";
import versicherer from "./2023-10-26-Versichererliste-Gesamt-neu-ab-2022-.pdf";
import policy from "./datenschutz-richtlinie.pdf";
import partner from "./datenschutz-geschaeftspartnerliste.pdf";
import { PiFilePdfLight } from "react-icons/pi";

function Privacy() {
  return (
    <div className="section sec-privacy">
      <div className="container">
        <p className="title">
          A. Datenschutz-Richtlinie
          <br />
          <p className="small">
            Gesellschaft für Versicherungsservice Moll mbH • Jupiterweg 1, 85586
            Poing • Tel: 08121 / 476784-0 • Fax: 08121 / 476784-9
          </p>
        </p>
        <p>
          Bei allen Fragen rund um den Schutz Ihrer Daten erhalten Sie Auskunft
          durch unsere Geschäftsführung, Sie erreichen uns über die vorgenannten
          Kontaktdaten.
        </p>
        <p>
          Sie haben <b>Beschwerderecht</b> bei der Aufsichtsbehörde, in deren
          Bundesland das Unternehmen seinen Sitz hat. Für unser Unternehmen ist
          dies:
        </p>
        <p>
          Bayerisches Landesamt für Datenschutzaufsicht
          <br />
          Promenade 27 (Schloss), 91522 Ansbach
          <br />
          Tel. 0981 53-1300
          <br />
          Fax 0981 53-5300
          <br />
          http://www.lda.bayern.de
        </p>
        <p>
          Eine Datenübermittlung findet an Versicherer/ Dienstleister statt, um
          den von Ihnen gewünschten Versicherungsschutz einzudecken. Des
          Weiteren an öffentliche Stellen und Institutionen, sofern eine
          gesetzliche Verpflichtung besteht. Eine Übersicht über die
          vorgestellten Geschäftspartner sowie Versicherer händigen wir auf
          Wunsch aus.
        </p>
        <p className="subtitle">1. Geltungsbereich</p>
        <p>
          Diese Richtlinie regelt die datenschutzkonforme
          Informationsverarbeitung und die entsprechenden Verantwortlichkeiten
          beim obengenannten Unternehmen (und seiner/n Niederlassung/en) auf
          Basis der gesetzlichen Regelungen der Europäischen Datenschutz-
          Grundverordnung (DS-GVO) und Bundesdatenschutzgesetz (BDSGneu). Alle
          Mitarbeiter sind zur Einhaltung dieser Richtlinie verpflichtet.
        </p>
        <p>
          Sie richtet sich insbesondere an:
          <br />
          Mitarbeiter, Kunden und Interessenten, Versicherer und Dienstleister.
        </p>
        <p>Hierbei gelten folgende Grundsätze:</p>
        <ul className="list">
          <li>Wahrung der Persönlichkeitsrechte</li>
          <li>Zweckbindung personenbezogener Daten</li>
          <li>Transparenz</li>
          <li>Datenvermeidung und Datensparsamkeit</li>
          <li>Sachliche Richtigkeit/Aktualität der Daten</li>
          <li>Vertraulichkeit bei der Datenverarbeitung</li>
          <li>Sicherheit bei der Datenverarbeitung</li>
          <li>
            Löschung und Einschränkung der Verarbeitung von Daten auf
            Anforderung
          </li>
        </ul>
        <p className="subtitle">2. Begriffsdefinitionen (Art. 4 DS-GVO)</p>
        <p>
          Personenbezogene Daten sind Einzelangaben über persönliche oder
          sachliche Verhältnisse einer natürlichen Person (Betroffener).
          Beispiele: Name, Vorname, Geburtstag, Adressdaten, Vertragsdaten,
          E-Mail-Inhalte. Besondere personenbezogene Daten sind Angaben über
          rassische, ethnische Herkunft, politische Meinungen, religiöse oder
          philosophische Überzeugungen, Gewerkschaftszugehörigkeit, Gesundheit
          oder Sexualleben, sowie wirtschaftliche Verhältnisse.
        </p>
        <p>
          Verantwortliche Stelle ist jede Person oder Stelle, die
          personenbezogene Daten für sich selbst erhebt, verarbeitet oder nutzt
          oder dies durch andere im Auftrag vornehmen lässt.
        </p>
        <p className="subtitle">
          3. Erheben, Verarbeiten und Speichern personenbezogener Daten (Art. 5
          + 6 DS-GVO)
        </p>
        <p>
          Das Erheben, Verarbeiten und Speichern personenbezogener Daten in
          unserem Unternehmen geschieht auf Basis des von uns verwendeten
          Maklerauftrages und den mitgeltenden Dokumenten (wie z.B.
          Maklervollmacht, Einwilligung zur Datenverarbeitung, die separat
          unterzeichnet werden).
        </p>
        <p>
          Ohne eine konkrete Beauftragung und eine datenschutzrechtliche
          Einwilligungserklärung durch unsere Kunden werden wir nicht tätig (bei
          Kindern und Jugendlichen wird die Einwilligung durch die
          Erziehungsberechtigten erteilt). Wir dokumentieren unsere Tätigkeit
          umfänglich über unser Maklerverwaltungsprogramm und halten konkrete
          Verfahrensanweisungen für die Ausführung unserer Aufträge vor.
          Profiling findet in unserem Unternehmen nicht statt. Die Daten werden
          ausschließlich zu den vereinbarten Zwecken verarbeitet.
        </p>
        <p>
          Die Daten unserer Kunden werden nach Kündigung des Maklervertrages
          nach den gesetzlichen Vorgaben, insbesondere der Bestimmungen zu
          gesetzlichen Aufbewahrungs-fristen gelöscht. Die Fristen können zur
          Verteidigung von möglichen Rechtsansprüchen entsprechend verlängert
          werden. Anstelle der Löschung tritt die Einschränkung der
          Verarbeitung.
        </p>
        <p className="subtitle">4. Verpflichtung auf Vertraulichkeit</p>
        <p>
          Alle Mitarbeiter werden bei der Aufnahme ihrer Tätigkeit zur
          Verschwiegenheit und der Einhaltung der Arbeitsanweisungen sowie
          dieser Richtlinie verpflichtet. Die Verpflichtung wird jährlich
          erneuert.
        </p>
        <p className="subtitle">5. Verarbeitungsübersichten (Art. 30 DS-GVO)</p>
        <p>
          Mittels interner Verfahrensübersichten (Verzeichnis der
          Verarbeitungstätigkeiten) schaffen wir Transparenz innerhalb des
          Unternehmens und überprüfen, ob unsere Verfahren besondere Risiken für
          die Rechte und Freiheiten der Betroffenen aufweisen und damit einer
          Vorabkontrolle/ Datenschutz-Folgeabschätzung unterliegen. Es besteht
          die Verpflichtung, diese Übersichten vorzuhalten für eine
          Einsichtnahme durch die Behörden.
        </p>
        <p className="subtitle">6. Beschaffung von Hard- und Software</p>
        <p>
          Sämtliche für unsere Arbeitsabläufe notwendige Hardware (Rechner,
          Bildschirme, Tastatur, Maus und Peripheriegeräte wie Scanner oder
          Drucker) wird nach internen Richtlinien gesteuert. Die Rechner werden
          für die Mitarbeiter bereits konfiguriert und mit den entsprechenden
          Programmen, die wir im Standard nutzen, ausgestattet. Weitere Software
          darf nur in Absprache mit der Geschäftsführung installiert werden.
        </p>
        <p className="subtitle">7. Passwortrichtlinien</p>
        <p>
          Um die Zugriffe zu unseren Systemen sicher zu gestalten, ist eine
          individuelle Authentifizierung notwendig. Für diese wurden interne
          Regelungen getroffen, an die sich alle Beteiligten halten müssen.
        </p>
        <p className="subtitle">8. Technische und organisatorische Maßnahmen</p>
        <p>
          Wir ergreifen alle uns möglichen Maßnahmen, die nach dem aktuellen
          Stand der Technik, sowie organisatorisch dazu geeignet sind, um
          Unbefugten keinen Zugriff auf die bei uns gespeicherten
          personenbezogenen Daten zu gewähren. Dazu führen wir separate
          Aufzeichnungen, um die Anforderungen an die Sicherheit der
          Datenverarbeitung zu dokumentieren.
          <br />
          Eine Übermittlung in Drittländer ist zum aktuellen Zeitpunkt nicht
          geplant.
        </p>
        <p className="subtitle">
          9. Rechte von Betroffenen (Art. 12 -23 DS-GVO)
        </p>
        <ol className="nlist">
          <li>
            Der Betroffene kann Auskunft darüber verlangen, welche
            personenbezogenen Daten welcher Herkunft über ihn zu welchem Zweck
            gespeichert sind. Falls im Arbeitsverhältnis nach dem jeweils
            anzuwendenden Arbeitsrecht weitergehende Einsichtsrechte in
            Unterlagen des Arbeitgebers (z.B. Personalakte) vorgesehen sind, so
            bleiben diese unberührt.
          </li>
          <li>
            Werden personenbezogene Daten an Dritte übermittelt, muss auch über
            die Identität des Empfängers oder über die Kategorien von Empfängern
            Auskunft gegeben werden.
          </li>
          <li>
            Sollten personenbezogene Daten unrichtig oder unvollständig sein,
            kann der Betroffene ihre Berichtigung oder Ergänzung verlangen.
          </li>
          <li>
            Der Betroffene kann der Verarbeitung seiner personenbezogenen Daten
            zu Zwecken der Werbung oder der Markt¬ und Meinungsforschung
            widersprechen. Für diese Zwecke müssen die Daten für die
            Verarbeitung eingeschränkt (gesperrt) werden.
          </li>
          <li>
            Der Betroffene ist berechtigt, die Löschung seiner Daten zu
            verlangen, wenn die Rechtsgrundlage für die Verarbeitung der Daten
            fehlt oder weggefallen ist. Gleiches gilt für den Fall, dass der
            Zweck der Datenverarbeitung durch Zeitablauf oder aus anderen
            Gründen entfallen ist. Bestehende Aufbewahrungspflichten und einer
            Löschung entgegenstehende schutzwürdige Interessen müssen beachtet
            werden.
          </li>
          <li>
            Der Betroffene hat ein grundsätzliches Widerspruchsrecht gegen die
            Verarbeitung seiner Daten mit Wirkung auf die Zukunft, das zu
            berücksichtigen ist, wenn sein schutzwürdiges Interesse aufgrund
            einer besonderen persönlichen Situation das Interesse an der
            Verarbeitung überwiegt. Dies gilt nicht, wenn eine Rechtsvorschrift
            zur Durchführung der Verarbeitung verpflichtet.
          </li>
          <li>
            Der Betroffene hat ein Recht auf Datenübertragbarkeit. Das bedeutet
            das Recht, die personenbezogenen Daten in einem strukturierten,
            gängigen und maschinenlesbaren Format zu erhalten. Freiheiten und
            Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt
            werden.
          </li>
          <li>
            Der Betroffene hat ein Beschwerderecht bei der Aufsichtsbehörde, in
            deren Bundesland das Unternehmen seinen Sitz hat. Die Kontaktdaten
            finden Sie zu Beginn der Beschreibung unserer
            Datenschutzorganisation.
          </li>
        </ol>
        <p className="subtitle">
          10. Verfahren bei "Datenpannen" (Art. 33 DS-GVO)
        </p>
        <p>
          Jeder Mitarbeiter soll seinem jeweiligen Vorgesetzten, der
          Geschäftsführung oder dem DSB unverzüglich Fälle von Verstößen gegen
          diese Datenschutzrichtlinie oder andere Vorschriften zum Schutz
          personenbezogener Daten (Datenschutzvorfälle) melden. Die
          verantwortliche Führungskraft ist verpflichtet, den DSB umgehend über
          Datenschutzvorfälle zu unterrichten.
        </p>
        <p>
          In Fällen von unrechtmäßiger Übermittlung personenbezogener Daten an
          Dritte, unrechtmäßigem Zugriff durch Dritte auf personenbezogene
          Daten, oder bei Verlust personenbezogener Daten sind die im
          Unternehmen vorgesehenen Meldungen unverzüglich vorzunehmen, damit
          nach staatlichem Recht bestehende Meldepflichten von
          Datenschutzvorfällen erfüllt werden können.
        </p>
        <p className="title">
          B. Änderungen innerhalb der Datenschutz-Richtlinie
        </p>
        <p>
          Wir behalten uns vor, die Datenschutz-Richtlinie bei Bedarf
          anzupassen, damit diese den aktuellen rechtlichen und technischen
          Anforderungen entspricht. Diese gelten dann bei einem erneuten Besuch.
          Auf eine Änderung weisen wir durch den Revisionsstand hin.
        </p>
        <p className="small">Revisions-Stand 22-02</p>
        <p>
          <Link to={versicherer} target="blank">
            <PiFilePdfLight /> Lister der Versicherer
          </Link>
          <br />
          <Link to={policy} target="blank">
            <PiFilePdfLight /> Datenschutzrichtlinie
          </Link>
          <br />
          <Link to={partner} target="blank">
            <PiFilePdfLight /> Geschäftspartnerliste
          </Link>
        </p>
      </div>
    </div>
  );
}

export default Privacy;
