import Address from "./partials/Address";
import Information from "./partials/Information";

function Footer() {
  return (
    <div className="section sec-footer">
      <div className="container">
        <div className="address-info">
          <Address />
          <p className="small">
            <i>
              * Wenn Sie uns eine E-Mail zusenden, wird Ihre E-Mail-Adresse von
              uns, bis zum jederzeit möglichen Widerruf durch Sie, elektronisch
              gespeichert. Wir verweisen auf den Inhalt unserer{" "}
              <a href="/datenschutz" title="Datenschutzerklärung">
                Datenschutzerklärung
              </a>
              .
            </i>
          </p>
        </div>

        <Information />
      </div>
    </div>
  );
}

export default Footer;
