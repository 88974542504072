import { MdLocalPhone, MdOutlineEmail, MdOutlineFax } from "react-icons/md";
import { PiGlobeLight } from "react-icons/pi";

function Address() {
  return (
    <div className="address">
      <address>
        <p aria-label="Name">Gesellschaft für Versicherungsservice Moll mbH</p>
        <p aria-label="Beschreibung">Versicherungsmakler</p>
        <div>
          <p aria-label="Straße">Jupiterweg 1</p>
          <p>
            <span arial-label="PLZ">D-85586</span>{" "}
            <span aria-label="Stadt">Poing</span>
          </p>
          <br />
          <p>
            <MdLocalPhone />{" "}
            <span aria-label="Rufnummer">+49 (0) 8121 47 67 84 - 0</span>
          </p>
          <p>
            <MdOutlineFax />{" "}
            <span aria-label="Faxnummer">+49 (0) 8121 47 67 84 - 9</span>
          </p>
          <p>
            <MdOutlineEmail />{" "}
            <a
              href="mailto:info@gvsmoll.de"
              aria-label="E-Mail-Adresse"
              title="E-Mail-Adresse"
            >
              info@gvsmoll.de
            </a>
          </p>
          <p>
            <PiGlobeLight />{" "}
            <a
              href="https://www.gvsmoll.de"
              aria-label="Website"
              title="Website URL"
            >
              www.gvsmoll.de
            </a>
          </p>
        </div>
        <br />
      </address>
    </div>
  );
}

export default Address;
