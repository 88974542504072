import pcust from './img/kelli-mcclintock-wBgAVAGjzFg-unsplash.jpg';
import bcust from './img/mediensturmer-aWf7mjwwJJo-unsplash.jpg';

function Service() {
  return (
    <>
      <section className="section sec-service">
        <div id="service"></div>
        <div className="container">
          <h1 className="title">Unser Service</h1>
          <span>
            Zu Beginn unserer Zusammenarbeit überprüfen wir den Status Ihrer bestehenden Versicherungen in Bezug auf
            Preis und Leistung und besprechen mit Ihnen Ihre individuelle Situation um Ihren tatsächlichen Bedarf zu
            ermitteln. Mit einer von Ihnen erteilten Maklervollmacht können wir dann für Sie tätig werden.
          </span>
          <span>
            Wir entlasten Sie von zeitraubender Verwaltungsarbeit und bleiben mit Ihnen in Kontakt um laufend den
            Versicherungsschutz an sich verändernde Risikoverhältnisse anpassen zu können. Im Schadenfall verhandeln wir
            mit Knowhow in Ihrem Interesse mit den Versicherern und sorgen für eine rasche&nbsp;Regulierung.
          </span>
          <span>
            Unsere Leistungen sind mit den von den Versicherern gezahlten Courtagen, die Teil der Versicherungsbeiträge
            sind, abgegolten. Weitere Kosten entstehen nicht. Nur in speziellen Bereichen schließen wir mit unseren
            Kunden Honorarvereinbarungen ab.
          </span>
        </div>
      </section>

      <section className="section svc-customers">
        <div className="container">
          <div className="customers">
            <h2 className="title">Gewerbliche Kunden</h2>
            <p>
              Vom Einzelunternehmer über mittelständische Produktions-, Handels- oder Handwerksfirmen bis hin zu
              multinationalen Industriekonzernen – unsere Firmenkunden stammen aus unterschiedlichen Branchen, gleich ob
              klein oder groß.
            </p>
            <p>
              Für unsere gewerblichen und industriellen Kunden haben wir zahlreiche Konzeptlösungen mit speziellen
              Rahmenverträgen abgeschlossen. Hier decken wir die Bereiche der industriellen Sachversicherungen, KFZ
              Flottenversicherungen, Transport und technischen Versicherungen genauso ab wie die existentiellen Risiken
              der Haftpflicht und D&O Deckungen.
            </p>
          </div>
          <div className="customers">
            <h2 className="title">Privatkunden</h2>
            <p>
              Auch die Mitarbeiter unserer Großkunden wissen unsere Dienste zu schätzen – in diesem Bereich beraten wir
              daher auch intensiv den privaten Haushalt.
            </p>
            <br />
            <p>
              Ob betriebliche oder private Altersvorsorge, Schutz des Eigentums oder Absicherung von Haftungsrisiken -
              für unsere privaten Kunden bestehen verschiedene Möglichkeiten Sonderkonzepten mit hervorragenden
              Beitrags- und Bedingungsvorteilen beizutreten.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Service;
