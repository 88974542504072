import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "./logo.svg";
import { MdOutlineHome } from "react-icons/md";

function PageHeader() {
  const [open, setOpen] = useState(false);

  return (
    <header className="page-header">
      <div className="container">
        <div className="brand">
          <img src={logo} alt="GVS Moll mbH" />
        </div>

        <div className="page-menu">
          <div
            className={`hamburger-btn ${open ? "open" : ""}`}
            onClick={() => setOpen((open) => !open)}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <ul className={`nav ${open ? "open" : ""}`}>
            <li>
              <Link to="/" onClick={() => setOpen((open) => !open)}>
                <MdOutlineHome />
              </Link>
            </li>
            {/* <li>
              <a href="#service" onClick={() => setOpen((open) => !open)}>
                Unser Service
              </a>
            </li>
            <li>
              <a href="#contact" onClick={() => setOpen((open) => !open)}>
                Kontakt
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </header>
  );
}

export default PageHeader;
